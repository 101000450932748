
// 购车类型
export const purchaseTypeList = [
  { label: 'Private', value: '00' },
  { label: 'Company', value: '01' }
]

// 车辆所有人证件类型
export const cardTypeList = [
  { label: 'MyKad', value: '1' },
  // { label: '护照', value: '2' },
  { label: 'MyPR', value: '3' },
  { label: 'Passport', value: '4' },
  // { label: '台胞证', value: '5' }
]
