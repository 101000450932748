<template>
  <div class="footer">
    <div class="footer-wrap space-between">
      <div>
        MSRP <span class="price">RM {{ formatPrice }}</span>
      </div>
      <div>
        <Button v-if="step === 0" @click="$emit('on-cancel')" size="small" type="info" plain round>Cancel</Button>
        <Button v-if="step > 0" @click="$emit('on-last')" size="small" type="info" plain round>Back</Button>
        <Button v-if="step !== maxStep" @click="$emit('on-next')" size="small" type="info" round>Next</Button>
        <Button v-if="step == maxStep" @click="$emit('on-save')" size="small" type="info" round>Submit</Button>
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'
import { formateToThousands } from "@/utils/utils.js"

export default {
  components: {
    Button
  },
  props: {
    step: {
      type: Number,
      default: 0
    },
    maxStep: {
      type: Number,
      default: 0
    },
    totalPrice: {
      type: Number,
      default: 0
    }
  },
  computed: {
    formatPrice() {
      return formateToThousands(this.totalPrice.toFixed(2))
    }
  }
}
</script>
<style lang="less" scoped>
.footer {
  height: 182px;
  line-height: 98px;
  font-size: 32px;
  background-color: #2B2B31;

  .footer-wrap {
    padding: 24px;
    height: 100%;
    align-items: flex-start;

    .price {
      font-weight: 800;
      color: #FF2E2D;
      margin-left: 10px;
    }

    /deep/ .van-button--plain {
      border: 2px solid #DA3738;
      background-color: transparent!important;
    }

    & > div {
      line-height: 1;
      color: #DA3738;
    }

    & > div /deep/ .van-button {
      width: 1.973rem;
      height: .773rem;
      border-radius: .107rem;
      background-color: #DA3738;
      margin-left: .24rem;
    }
  }
}
</style>