<template>
  <div class="step-content">
    <div class="detail">
      <h3 class="detail-name">
        D-MAX
      </h3>
      <div class="detail-opts">{{ vehicleInfo.vehicleDesc }}</div>
      <div class="detail-price">RM {{ formatPrice }}</div>
    </div>
    <CarOwnerModel ref="carOwnerModel" :info="customerInfo" />
  </div>
</template>
<script>
import CarOwnerModel from "_c/business/carOwnerModel";
import { formateToThousands } from "@/utils/utils.js";

export default {
  components: {
    CarOwnerModel,
  },
  props: {
    customerInfo: {
      type: Object,
      default: () => ({}),
    },
    vehicleInfo: {
      type: Object,
      default: () => ({}),
    },
    carSeriesInfo: {
      type: Object,
      default: () => ({}),
    },
    carType: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    formatPrice() {
      const price = (
        +this.vehicleInfo.showCarPrice +
        +this.vehicleInfo.showCarDeposit +
        +this.vehicleInfo.showIntentionMoney
      ).toFixed(2);
      return formateToThousands(price);
    },
  },
  methods: {
    validate() {
      return this.$refs.carOwnerModel.validate();
    },
  },
};
</script>
<style lang="less" scoped>
.step-content {
  padding-top: 16px;

  .detail {
    margin-bottom: 16px;
    padding: 26px 24px 33px;
    background-color: #fff;

    .detail-name {
      font-size: 32px;
      color: #333333;
      line-height: 72px;
    }

    .detail-opts {
      margin-top: 16px;
      font-size: 24px;
      color: #666;
    }

    .detail-price {
      margin-top: 8px;
      font-size: 24px;
      color: #5f5f6a;
    }
  }
}
</style>