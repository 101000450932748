<template>
  <div class="steps">
    <Steps
      :active="value"
      active-color="#DA3738"
    >
      <Step
        v-for="(item, index) in stepList"
        :key="index"
      >
        {{ item.label }}
      </Step>
    </Steps>
  </div>
</template>
<script>
import { Step, Steps } from 'vant'
export default {
  components: {
    Steps,
    Step
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    stepList: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.van-step--horizontal .van-step__circle-container,
/deep/.van-step--horizontal .van-step__line {
  top: 0;
}
/deep/.van-step--horizontal .van-step__title {
  font-size: .32rem;
  line-height: .96rem;
  color: #666;

  &.van-step__title--active {
    color: #666 !important;
  }
}
/deep/.van-step--horizontal:first-child .van-step__title {
  transform: translateX(-50%);
}
/deep/.van-step--horizontal:last-child .van-step__title {
  transform: translateX(50%);
}
/deep/.van-steps--horizontal {
  padding: .133rem .6rem 0;
}
/deep/.van-steps--horizontal .van-steps__items {
  padding: 0;
  margin: 0;
}
/deep/.van-step__line {
  background-color: #eee;
}
/deep/.van-step__circle {
  width: .187rem;
  height: .187rem;
  background-color: #BBB;
}
/deep/.van-step__icon--active {
  position: relative;
  width: .213rem;
  height: .213rem;
  border-radius: 50%;
  background-color: #DA3738;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    width: .347rem;
    height: .347rem;
    border-radius: 50%;
    background-color: #D1E5FC;
  }
}
.steps {
  height: 150px;
  padding: 48px 22% 0;
  box-sizing: border-box;
  background-color: #fff;
}
</style>