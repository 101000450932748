<template>
  <DynamicForm
    ref="form"
    v-model="model"
    :rules="rules"
    :fieldData="fieldData"
  />
</template>
<script>
import { getArea } from "@/api/order/index";
import DynamicForm from "_c/dynamicForm";
import { purchaseTypeList, cardTypeList } from "./attr";
import { Toast } from "vant";

export default {
  name: "carOwnerModel",
  components: {
    DynamicForm,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    info: {
      handler(info) {
        if (info) {
          if (info.purchaseType) this.model.purchaseType = info.purchaseType;
          if (info.ownerName) this.model.ownerName = info.ownerName;
          if (info.ownerPhone) this.model.ownerPhone = info.ownerPhone;
          if (info.ownerCardType) this.model.ownerCardType = info.ownerCardType;
          if (info.ownerCardNo) this.model.ownerCardNo = info.ownerCardNo;
          if (info.province) this.model.provinceId = info.province;
          if (info.city) this.model.cityId = info.city;
          if (info.enterpriseName)
            this.model.enterpriseName = info.enterpriseName;
          if (info.enterpriseCode)
            this.model.enterpriseCode = info.enterpriseCode;
          if (info.enterpriseAddr)
            this.model.enterpriseAddr = info.enterpriseAddr;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      model: {
        purchaseType: "00",
        ownerName: this.info.name || "",
        ownerPhone: this.info.phone || "",
        ownerCardType: "1",
        ownerCardNo: "",
        provinceId: "",
        cityId: "",
        enterpriseName: "",
        enterpriseCode: "",
        enterpriseAddr: "",
      },
      rules: {
        purchaseType: [{ required: true, message: "Please select buyer type" }],
        ownerName: [{ required: true, message: "Please fill in the name" }],
        ownerPhone: [
          { required: true, message: "Please fill in the phone number" },
          // { pattern: this.$reg.mobile.reg, message: this.$reg.mobile.msg },
        ],
        ownerCardType: [{ required: true, message: "Please select the ID type" }],
        ownerCardNo: [
          { required: true, message: "Please fill in the ID number" },
          { pattern: this.$reg.cardNo.reg, message: this.$reg.cardNo.msg },
        ],
        cityId: [{ required: true, message: "Please select the city of registration" }],
        enterpriseName: [{ required: true, message: "Please fill in the company name" }],
        enterpriseCode: [{ required: true, message: "Please fill in the business registration number" }],
        enterpriseAddr: [{ required: true, message: "Please fill the company address" }],
      },

      areaList: [],
    };
  },
  computed: {
    channel() {
      return this.$storage.get("channel");
    },
    fieldData() {
      return [
        {
          data: [
            {
              label: "Type",
              key: "purchaseType",
              type: "select",
              options: purchaseTypeList,
            },
            {
              label: "Company Name",
              key: "enterpriseName",
              props: { maxLength: 30 },
              visible: +this.model.purchaseType === 1,
            },
            {
              label: "Business Registration Number",
              key: "enterpriseCode",
              props: { maxLength: 20 },
              visible: +this.model.purchaseType === 1,
            },
            { label: "Name", key: "ownerName", props: { maxLength: 20 } },
            { label: "Phone Number", key: "ownerPhone", props: { maxLength: 11 } },
            {
              label: "ID Type",
              key: "ownerCardType",
              type: "select",
              options: cardTypeList,
              visible: +this.model.purchaseType === 0,
            },
            {
              label: "ID No.",
              key: "ownerCardNo",
              props: { maxLength: 20 },
              visible: +this.model.purchaseType === 0,
            },
            {
              label: "City of Registration",
              key: "cityId",
              type: "select",
              options: this.areaList,
              props: { 'label-width': 130 },
              listeners: { "on-select": this.onSelectArea },
            },
            // {
            //   label: "企业地址",
            //   key: "enterpriseAddr",
            //   direction: "column",
            //   props: { type: "textarea", maxLength: 200 },
            //   visible: +this.model.purchaseType === 1,
            // },
          ],
        },
      ];
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then((valid) => {
        if (valid) {
          const data = { ...this.model };
          if (data.purchaseType === "00") {
            // 个人
            delete data.enterpriseName;
            delete data.enterpriseCode;
          } else {
            // 企业
            data.ownerCardNo = data.enterpriseCode;
            data.ownerCardType = "6";
          }
          data.ownerType = data.purchaseType;
          return data;
        }
        return false;
      });
    },
    // 选择省市
    onSelectArea(selected) {
      this.model.provinceId = selected[0].value;
    },
    // 获取省市数据
    getArea() {
      /* Toast.loading({
        message: "Loading...",
        forbidClick: true,
        loadingType: "spinner",
      }); */
      getArea({ channel: this.channel })
        .then((res) => {
          if (res.success) {
            const formatData = (data) => {
              if (Array.isArray(data) && data.length) {
                for (const item of data) {
                  item.label = item.name;
                  item.value = item.areaCode;
                  if (item.city && item.city.length) {
                    item.children = item.city || [];
                    delete item.city;
                  }
                  formatData(item.children);
                }
              }
              return data;
            };
            this.areaList = formatData(res.data);
          }
        })
        .finally(() => {
          Toast.clear();
        });
    },
  },
  created() {
    this.getArea();
  },
};
</script>
